import * as React from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl';


const StyledBreakfast = styled.div`
padding-top:4%;
letter-spacing:1px !important;

  width: 80%;
display: flex;
margin-left:auto;
margin-right:auto;
justify-content: center;
font-family: "Theano Didot", Regular;
color:rgb(68, 68, 68);
  @media screen and (max-width: 1200px) {
display:block;
   width:100%;
   }
       @media screen and (max-width: 960px) {
padding-top:2em;

  
   
   }


`;

const StyledP = styled.p`
font-family: "Theano Didot", Regular;
font-style: italic;
font-size: 200%;
text-align: right;
line-height: 50px;
letter-spacing: 2px;
color:rgb(68, 68, 68);
width:25%;
height:100%;
margin-right:7%;

  @media screen and (max-width: 1200px) {
height:auto;
width:100%;
text-align:center;
margin-bottom:1em;

   }
   
 


`;

const DesktopBr = styled.br`
  @media screen and (max-width: 1200px) {
display:none;

   }
`

const Einzug = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
line-height: 1.5em;
color:rgb(68, 68, 68);
text-indent: 20px;

  @media screen and (max-width: 1200px) {
text-align:center;   }  

@media screen and (max-width: 500px) {
text-align:left;   }
`;

const Bold = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
line-height: 1.5em;
margin-bottom: 1.0em;
color:rgb(68, 68, 68);
font-weight: bold;
width:100%;
margin-top:1.3%;
text-align:center;




`

const Div = styled.div`
width:32%;
height:100%;
padding-bottom:30px;


  @media screen and (max-width: 1200px) {
height:auto;
width:80%;
margin-left:auto;
margin-right:auto;

   }

`


interface BreakfastProps {
  className?: string
}

const Breakfast: React.FC<BreakfastProps> = ({ }) => (
  <StyledBreakfast>
<StyledP><FormattedMessage id="breakfast_text_1" /> <DesktopBr/> <FormattedMessage id="breakfast_text_2" /> <br/> in Vilhelm7 !</StyledP>
    <Div>
    <Einzug><FormattedMessage id="breakfast_text_4" /> <FormattedMessage id="breakfast_text_5" />  </Einzug>
<Bold><FormattedMessage id="breakfast_text_6" /></Bold>
    </Div>



  </StyledBreakfast>

)

export default Breakfast

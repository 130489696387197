import * as React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import Helmet from 'react-helmet'

import Page from '../components/Page'
import Container from '../components/Container'
import Breakfast from '../components/breakfast/Breakfast'
import IndexLayout from '../layouts'
import BreakfastSlider from "../components/swipesliders/BreakfastSlider";
import NavigationDesktop from "../components/NavigationDesktop";
import HeaderDesktop from "../components/HeaderDesktop";
const StyledBreakfast = styled(Breakfast)`

`


const BreakfastPage = ( {pathContext: { locale }}) => (

  <IndexLayout locale={locale}>
    <Helmet

      title={'Frühstück'}
      meta={[
        { name: 'description', content: 'Vilhelm7 V7 Apartments Hotel Zimmer Berlin billig preiswert günstig buchen Übernachtung' },
        { name: 'keywords', content: 'Vilhelm7, V7, Apartments, buchen, Hotel, Zimmer, Berlin, Übernachtung'},
        {name: 'page-type', content: 'Hotel, Apartments'},
        { name:'copyright', content:"https://www.vilhelm7.de"},
        { name:'X-UA-Compatible', content:"IE=edge"},


      ]}

    />

    <HeaderDesktop></HeaderDesktop>
    <BreakfastSlider></BreakfastSlider>
    <StyledBreakfast> </StyledBreakfast>

  </IndexLayout>
)

export default BreakfastPage

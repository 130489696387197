import * as React from 'react'
import styled from '@emotion/styled'
import Swiper from 'react-id-swiper';
require('../../../node_modules/slick-carousel/slick/slick.css');
require('../../../node_modules/slick-carousel/slick/slick-theme.css');
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {Component} from "react";
import BrImg1 from './BrImg1'
import BrImg2 from './BrImg2'
import BrImg3 from './BrImg3'
import BrImg4 from './BrImg4'
import BrImg5 from './BrImg5'
import BrImg6 from './BrImg6'




const settings = {
  dots: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 0,
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  slidesToShow:2,
  speed: 11000,
  easing:'linear',
  slidesToScroll: 1

};
const StyledSlider = styled(Swiper)`
  display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
`




const StyledCarousel = styled(Carousel)`
width:80%;
margin-left:auto;
margin-right:auto;

.carousel .slide{
        background:rgba(0,0,0,.1) !important;

}

        background:rgba(0,0,0,.1) !important;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    li:nth-child(3){
min-width: 49.1% !important;  }

 li:nth-child(6){
min-width: 50.05% !important;  }

li:nth-child(7){
min-width: 50.1% !important;  }

 li:nth-child(1){
min-width: 50.3% !important;  }
 li:nth-child(2){
min-width: 50% !important;  }

li:nth-child(4){
min-width:45.6% !important;
}

      @media screen and (max-width: 1200px) {

width:100%;
z-index:0;

}

     @media screen and (max-width: 1200px) {
width:100%;
}

      @media screen and (max-width: 960px) {
display:none;
}
`

const StyledMobileCarousel = styled(Carousel)`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display:none;
    background:rgba(68,68,68,.2) !important;



li{
background:none !important;
}
     @media screen and (max-width: 960px)
    {
    display:block;
    }


  `





const params = {
  height:200,
  speed:9000,
  watchSlidesVisibility: true,
  loop:true,
  slidesPerView:2,
  spaceBetween: 0,
  autoplay: {
    delay: 1000,
  },


};

export default class BreakfastSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 2,
      isOpen: false,
    };
  }
  render() {
    const { photoIndex, isOpen } = this.state;

    return (
      <div>
  <StyledCarousel infiniteLoop dynamicHeight centerMode centerSlidePercentage={50} showIndicators={false}  showStatus={false} showThumbs={false} autoPlay interval={5000} transitionTime={1500} >

<BrImg1/>
<BrImg2/>
<BrImg3/>
<BrImg4/>
<BrImg5/>
<BrImg6/>





  </StyledCarousel>

      <StyledMobileCarousel infiniteLoop dynamicHeight showIndicators={false}  showStatus={false} showThumbs={false} autoPlay interval={5000} transitionTime={600} >

        <BrImg1/>
        <BrImg2/>
        <BrImg3/>
        <BrImg4/>
        <BrImg5/>



  </StyledMobileCarousel>

      </div>
    );
  }
}
